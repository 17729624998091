<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">结算列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input v-model.trim="searchData.projectCode" clearable placeholder="请输入班级编码" size="small"></el-input>
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6.5rem">班级名称:</span>
              <el-input v-model.trim="searchData.projectName" clearable placeholder="请输入班级名称" size="small"></el-input>
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">行政区划:</span>
              <el-cascader clearable filterable v-model="searchData.areaId" :options="areatreeList" :props="propsarea"
                size="small"></el-cascader>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:10rem">机构名称:</span>
              <el-select size="small" v-model.trim="searchData.signCompId" remote :remote-method="getCompanyList"
                filterable clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="班级状态" class="searchboxItem ci-full">
              <span class="itemLabel">班级状态:</span>
              <el-select size="small" v-model="searchData.projectState" placeholder="请选择班级状态" clearable>
                <el-option v-for="item in ClassstatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6.5rem">培训类型:</span>
              <el-cascader style="width:100%;" size="small" :props="{
                emitPath: false,
                value: 'id',
                label: 'label',
                children: 'children',
                checkStrictly: true,
              }" v-model="searchData.trainTypeId" :options="trainTypeList" clearable
                @change="handleTrainType"></el-cascader>
            </div>
            <div title="结算单状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">收款结算单状态:</span>
              <el-select size="small" v-model="searchData.billState" clearable placeholder="请选择收款结算单状态">
                <el-option v-for="item in settlementStatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="收款结算单到账状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:10rem">收款结算单到账状态:</span>
              <el-select size="small" v-model="searchData.arrival" clearable placeholder="请选择收款结算单到账状态">
                <el-option v-for="item in settlementArrivedList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="是否完结" class="searchboxItem ci-full">
              <span class="itemLabel">是否完结:</span>
              <el-select size="small" v-model="searchData.billFinishType" placeholder="请选择是否完结" clearable>
                <el-option v-for="item in billFinishTypeList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="收款结算单号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6.5rem">收款结算单号:</span>
              <el-input v-model="searchData.billNo" clearable placeholder="请输入收款结算单号" size="small"></el-input>
            </div>
            <div title="付款结算单号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">付款结算单号:</span>
              <el-input v-model="searchData.payBillNo" clearable placeholder="请输入付款结算单号" size="small"></el-input>
            </div>
            <div title="合同对接业务员" class="searchboxItem">
              <span class="itemLabel" style="min-width:10rem">合同对接业务员:</span>
              <el-input v-model="searchData.contractSalesman" clearable placeholder="请输入合同对接业务员" size="small"></el-input>
              <!-- <el-select
                  v-model="searchData.salesmanId"
                  placeholder="请选择"
                  size="small"
                   clearable
                   filterable
                >
                  <el-option
                    v-for="item in SalesmanList"
                    :key="item.adminId"
                    :label="item.fullname"
                    :value="item.adminId"
                  ></el-option>
                </el-select> -->
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="开始时间" class="searchboxItem ci-full">
              <span class="itemLabel">开始时间:</span>
              <el-date-picker clearable size="small" v-model="searchData.startDate" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div title="结束时间" class="searchboxItem ci-full">
              <span class="itemLabel">结束时间:</span>
              <el-date-picker clearable size="small" v-model="searchData.endDate" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div title="代理结算单号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">代理结算单号:</span>
              <el-input v-model="searchData.agencyBillNo" clearable placeholder="请输入代理结算单号" size="small"></el-input>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="结算类型" class="searchboxItem ci-full">
              <span class="itemLabel">结算类型:</span>
              <el-select size="small" v-model="searchData.settleType" placeholder="请选择结算类型" clearable>
                <el-option v-for="item in settleTypeList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="课程方机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">课程方机构名称:</span>
              <el-select size="small" v-model="searchData.courseProvideId" remote :remote-method="getCourseProvideList"
                filterable clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in courseProvideList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="代理商" class="searchboxItem ci-full">
              <span class="itemLabel">代理商:</span>
              <el-select size="small" v-model="searchData.agencyId" remote :remote-method="getAgencyList" filterable
                clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in AgencyList" :key="item.agencyId" :label="item.agencyName"
                  :value="item.agencyId"></el-option>
              </el-select>
            </div>
            <div title="客户性质" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">客户性质:</span>
              <el-select clearable placeholder="请选择客户性质" size="small" v-model="searchData.customerNature">
                <el-option v-for="item in customerNatureList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="上级单位" class="searchboxItem ci-full">
              <span class="itemLabel">上级单位:</span>
              <el-select size="small" v-model="searchData.parentCompId" remote :remote-method="getparentCompList"
                filterable clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in parentCompList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div class="df" style="padding-left:10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="doExport()">导出</el-button>
              <el-button type="primary" class="bgc-bv" round @click="NotesbatchUpload()">备注批量上传</el-button>
              <el-button type="primary" class="bgc-bv" round @click="exportCachedData()">导出缓存数据</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="260px" />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" min-width="180px" />
              <el-table-column label="机构名称" align="left" show-overflow-tooltip prop="compName" min-width="260px" />
              <el-table-column label="上级单位" align="left" show-overflow-tooltip prop="compNamePath" min-width="260px" />
              <el-table-column label="课程方机构名称" align="left" show-overflow-tooltip prop="courseProvideName"
                min-width="260px" />
              <el-table-column label="客户性质" align="left" show-overflow-tooltip min-width="100px">
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "CUSTOMER_NATURE",
                      scope.row.customerNature
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaName" min-width="150px" />
              <el-table-column label="培训类型" align="left" show-overflow-tooltip prop="trainTypeName" min-width="150px" />
              <el-table-column label="开班时间" align="left" show-overflow-tooltip prop="startDate" min-width="150px">
                <template slot-scope="scope">
                  {{ scope.row.startDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="结束时间" align="left" show-overflow-tooltip prop="endDate" min-width="150px">
                <template slot-scope="scope">
                  {{ scope.row.endDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="班级状态" align="left" show-overflow-tooltip prop="trainState" min-width="150px" />
              <el-table-column label="付款结算单号" align="left" show-overflow-tooltip prop="payBillNo" min-width="150px" />
              <el-table-column label="收款结算单编号" align="left" show-overflow-tooltip prop="billNo" min-width="200px" />
              <el-table-column label="收款结算单状态" align="left" show-overflow-tooltip prop="billState" min-width="150px" />
              <el-table-column label="国家补贴标准" align="left" show-overflow-tooltip prop="countrySubsidyStandard"
                min-width="150px" />
              <el-table-column label="应收平台技术服务费" align="left" show-overflow-tooltip prop="platformTechnicalFee"
                min-width="150px" />
              <el-table-column label="应收课程服务费" align="left" show-overflow-tooltip prop="courseFee" min-width="150px" />
              <el-table-column label="应收平台服务费" align="left" show-overflow-tooltip prop="platformTotalFee"
                min-width="150px" />
              <el-table-column label="平台服务总费用" align="left" show-overflow-tooltip prop="payerAmount" min-width="150px" />
              <el-table-column label="折扣" align="left" show-overflow-tooltip prop="settlementAmount" min-width="150px" />
              <el-table-column label="二次补贴人数费用" align="left" show-overflow-tooltip prop="secondFee" min-width="150px" />
              <el-table-column label="汇总金额" align="left" show-overflow-tooltip prop="finalFee" min-width="150px" />
              <el-table-column label="收款结算人数" align="left" show-overflow-tooltip prop="settleNum" min-width="150px" />
              <el-table-column label="收款结算单到账状态" align="left" show-overflow-tooltip prop="arrival" min-width="150px">
                <template slot-scope="scope">
                  {{ scope.row.arrival == '10' ? '未到账' : scope.row.arrival == '20' ? '已到账' : scope.row.arrival == '15' ?
                    '部分到账' : '' }}
                </template>
              </el-table-column>
              <el-table-column label="课程总学时" align="left" show-overflow-tooltip prop="totalLessonNum" min-width="150px" />
              <el-table-column label="公共课学时" align="left" show-overflow-tooltip prop="publicLessonNum"
                min-width="150px" />
              <el-table-column label="合同对接业务员" align="left" show-overflow-tooltip prop="contractSalesman"
                min-width="150px" />
              <el-table-column label="是否完结" align="center" max-width="150" fixed="right">
                <div slot-scope="scope" class="table-switch">
                  <el-switch :width="20" v-model="scope.row.billFinishType" :active-value="true" :inactive-value="false"
                    active-color="#13ce66" @change="(val) => {
                      handleStu(scope.row.billFinishType, scope.row.projectId, scope.$index);
                    }
                      "></el-switch>
                </div>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <div slot-scope="scope">
                  <el-button type="text" size="mini" style="padding:0 5px"
                    @click="EditDetail(scope.row.projectId)">详情</el-button>
                  <el-button type="text" size="mini" style="padding:0 5px"
                    @click="handleRemark(scope.row.projectId)">备注</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 备注弹框 -->
    <el-dialog title="备注" :visible.sync="dialogVisible" width="52%" top="3%" :before-close="handleClose">
      <div class="ovy-a" style="height:500px">
        <el-input v-model="remark" clearable maxlength="255" show-word-limit type="textarea" placeholder="请输入备注信息"
          size="small"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button class="bgc-bv" @click="doCancle" size="small">取 消</el-button>
          <el-button class="bgc-bv" @click="areaIdSure" size="small">确 定</el-button>
        </span>
        <div>
          <h4 style="padding:1rem 0">历史备注</h4>
          <el-table ref="multipleTable" :data="listData" size="small" tooltip-effect="dark" style="width: 100%"
            :header-cell-style="tableHeader" stripe>
            <el-table-column label="备注内容" align="left" show-overflow-tooltip prop="remark" min-width="150px">
              <template slot-scope="scope">
                <div v-if="scope.row.showInput">
                  <el-input v-model="scope.row.remark" clearable maxlength="255" show-word-limit type="textarea"
                    placeholder="请输入备注信息" size="small"></el-input>
                </div>
                <div v-else>
                  {{ scope.row.remark }}
                </div>

              </template>
            </el-table-column>
            <el-table-column label="备注人" align="left" show-overflow-tooltip prop="fullname" width="100px" />
            <el-table-column label="备注时间" align="left" show-overflow-tooltip prop="createTime" width="150px" />
            <el-table-column label="操作" align="center" width="100px" fixed="right">
              <div slot-scope="scope">
                <el-button v-if="!scope.row.showInput" type="text" size="mini" style="padding:0 5px"
                  @click="dialogEditDetail(scope.row, scope.$index, 'edit')">编辑</el-button>
                <el-button v-else type="text" size="mini" style="padding:0 5px"
                  @click="dialogEditDetail(scope.row, scope.$index, 'modify')">保存</el-button>
                <el-button type="text" size="mini" style="padding:0 5px"
                  @click="handleDelete(scope.row.projectRemarkId, scope.row.projectId)">删除</el-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <!-- 财务备注导入 -->
    <el-dialog title="财务备注导入" :visible.sync="centerDialogVisible" width="50%" center :before-close="doCloseload"
      class="exportdialog">
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span @click="downloadExcel"
              style="margin-top:20px;margin-right:20px;color:#409eff;cursor: pointer;">财务备注导入模版</span>
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin:20px 0 10px">
              <el-upload class="upload-demo upload-workers" :action="actionUrl" :on-error="handleError"
                :on-success="handleSuccess" :on-change="uploadChange" :show-file-list="false" :auto-upload="false">
                <el-button class="bgc-bv" style="font-size:12px;width: 80%;">浏览...</el-button>
              </el-upload>
              <p v-if="fileName" style="margin-top:10px;margin-left:10px">
                当前选择文件：
                <span style="color:#f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top:10px;margin-left:10px">未选择文件</p>
            </div>
            <div>
              <el-button class="bgc-bv" size="mini" style="margin-top:10px;height: 35px;"
                @click="doExportIn">开始导入</el-button>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum" style="padding:1rem 0 0 0">
            <span>班级数量:{{ projectCount }}个;</span>
            <span>成功:{{ successCount }}个;</span>
            <span>失败:{{ projectCount - successCount }}个;</span>
          </div>
          <div>
            <el-button class="bgc-bv" style="margin-top:15px;height: 35px;" size="mini"
              :disabled="projectCount - successCount == 0" @click="doExportError">导出错误数据</el-button>
          </div>
          <div style="margin-top:15px;color:#DD1D35;" v-if="projectCount - successCount != 0">
            有导入失败班级，请导出错误数据，将导入失败班级调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  //页面初始化时不自动查询列表数据
  doNotInit: true,
  data() {
    return {
      //查询条件
      searchData: {
        projectCode: "",
        projectName: "",
        areaId: "",
        signCompId: "",
        projectState: "",
        startTime: "",
        endDate: "",
        trainTypeId: "",
        billState: "",
        arrival: "",
        contractSalesman: '',
        billNo: '',
        billFinishType: '',
        payBillNo: '',
        courseProvideId: '',
        agencyId: '',
        agencyBillNo: '',
        parentCompId: "",
        customerNature: "", //客户性质
      },
      customerNatureList: [], //客户性质list
      parentCompList: [],
      AgencyList: [],
      CompanyList: [],
      courseProvideList: [],
      trainTypeList: [],
      settlementStatusList: [
        {
          label: "已结算",
          value: "20",
        },
        {
          label: "未结算",
          value: "10",
        },
      ],
      settlementArrivedList: [
        {
          label: "已到账",
          value: "20",
        },
        {
          label: "未到账",
          value: "10",
        },
        // {
        //   label: "部分到账",
        //   value: "15",
        // },
      ],
      billFinishTypeList: [
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      settleTypeList: [
        {
          label: "单次结算",
          value: '10',
        },
        {
          label: "多次结算",
          value: '20',
        },
      ],
      classstatuslist: [],
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      dialogVisible: false,
      remark: "",
      listData: [],
      centerDialogVisible: false,
      fileName: "",
      fileKey: "",
      errorCount: "0",
      projectCount: "0",
      successCount: "0",
      taskIds: []
    };
  },
  watch: {},
  created() { },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
    this.getclassstatusList();
    this.getareatree();
    this.getTraintype();
  },
  methods: {
    // 获取 - 代理商
    getAgencyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/mercantile/agency/getListByName", {
          agencyName: query,
        })
          .then((res) => {
            if (res.status == 0) {
              this.AgencyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.AgencyList = [];
      }
    },
    /* 修改是否开票 */
    handleStu(billFinishType, projectId, index) {
      this.$post("/biz/new/bill/project/finish", {
        projectId,
        billFinishType,
      })
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: ret.message,
              type: 'success'
            })
            this.getData(-1);
          }
        })
        .catch((err) => {
          if (err.data.status == '-1') {
            this.tableData[index].billFinishType = true;
          }
        });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    //班级状态获取
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        if (key == "30" || key == "50" || key == "60") {
          list.push({
            value: key,
            label: classstatuslist[key],
          });
        }
        this.ClassstatusList = list;
      }
      // 客户性质
      const customerNatureList = this.$setDictionary(
        "CUSTOMER_NATURE",
        "list"
      );
      for (const key in customerNatureList) {
        this.customerNatureList.push({
          value: key,
          label: customerNatureList[key],
        });
      }
    },
    // //获取市场人员
    // getSalesmanList() {
    //   this.$post("/biz/project/querySalesmanList", {
    //     compId: this.compId,
    //   }).then((ret) => {
    //     this.SalesmanList = ret.data;
    //   });
    // },
    // 上级单位
    getparentCompList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query.trim() })
          .then((res) => {
            if (res.status == 0) {
              this.parentCompList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.parentCompList = [];
      }
    },
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query.trim() })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /* 课程方机构名称 */
    getCourseProvideList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query.trim() })
          .then((res) => {
            if (res.status == 0) {
              this.courseProvideList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        billFinishType: this.searchData.billFinishType
      };
      if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.settleType) {
        params.settleType = this.searchData.settleType;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.billNo) {
        params.billNo = this.searchData.billNo;
      }
      if (this.searchData.payBillNo) {
        params.payBillNo = this.searchData.payBillNo;
      }
      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.billState) {
        params.billState = this.searchData.billState;
      }
      if (this.searchData.arrival) {
        params.arrival = this.searchData.arrival;
      }
      if (this.searchData.projectState) {
        params.projectState = this.searchData.projectState;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      if (this.searchData.signCompId) {
        params.compId = this.searchData.signCompId;
      }
      if (this.searchData.courseProvideId) {
        params.courseProvideId = this.searchData.courseProvideId;
      }
      if (this.searchData.agencyId) {
        params.agencyId = this.searchData.agencyId;
      }
      if (this.searchData.agencyBillNo) {
        params.agencyBillNo = this.searchData.agencyBillNo;
      }
      if (this.searchData.endDate) {
        params.endDateBegin = this.searchData.endDate[0];
        params.endDateEnd = this.searchData.endDate[1];
      }
      if (this.searchData.startDate) {
        params.startDateBegin = this.searchData.startDate[0];
        params.startDateEnd = this.searchData.startDate[1];
      }
      if (this.searchData.contractSalesman) {
        params.contractSalesman = this.searchData.contractSalesman;
      }
      if (this.searchData.parentCompId) {
        params.parentCompId = this.searchData.parentCompId;
      }
      if (this.searchData.customerNature) {
        params.customerNature = this.searchData.customerNature;
      }
      this.doFetch({
        url: "/biz/new/bill/project/page",
        params,
        pageNum,
      });
    },
    getDataremark(projectId) {
      this.$post("/biz/new/bill/project/remark/pageList", {
        projectId,
      })
        .then((ret) => {
          ret.data.map(el => {
            el.showInput = false
          })
          this.listData = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    areaIdSure() {
      let parmar = {
        projectId: this.projectId,
        remark: this.remark,
      };
      this.$post("/biz/new/bill/project/remark/insert", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.doCancle();
          }
        })
        .catch((err) => {
          return;
        });
    },
    //编辑
    EditDetail(projectId) {
      this.$router.push({
        path: "/web/financialSettlement/settlementDetails",
        query: {
          projectId,
        },
      });
    },
    //备注
    handleRemark(projectId) {
      this.dialogVisible = true;
      this.projectId = projectId;
      this.getDataremark(projectId);
    },
    //编辑
    dialogEditDetail(row, index, stu) {
      this.listData[index].showInput = true;
      if (stu == 'modify') {
        let parmar = {}
        parmar.projectRemarkId = row.projectRemarkId;
        parmar.remark = row.remark
        this.$post('/biz/new/bill/project/modify', parmar).then(ret => {
          this.listData[index].showInput = false;
        }).catch(err => {
          return;
        })
      }
    },
    //弹框关闭
    doCancle() {
      this.remark = "";
      this.dialogVisible = false;
    },
    //删除备注
    handleDelete(projectRemarkId, projectId) {
      this.$post('/biz/new/bill/project/delete', { projectRemarkId }).then(ret => {
        if (ret.status == '0') {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getDataremark(projectId)
        }
      }).catch(err => {
        return;
      })
    },
    doExport() {
      const params = {
        billFinishType: this.searchData.billFinishType
      };
      if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.billNo) {
        params.billNo = this.searchData.billNo;
      }
      if (this.searchData.payBillNo) {
        params.payBillNo = this.searchData.payBillNo;
      }
      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.billState) {
        params.billState = this.searchData.billState;
      }
      if (this.searchData.arrival) {
        params.arrival = this.searchData.arrival;
      }
      if (this.searchData.projectState) {
        params.projectState = this.searchData.projectState;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      if (this.searchData.signCompId) {
        params.compId = this.searchData.signCompId;
      }
      if (this.searchData.courseProvideId) {
        params.courseProvideId = this.searchData.courseProvideId;
      }
      if (this.searchData.endDate) {
        params.endDateBegin = this.searchData.endDate[0];
        params.endDateEnd = this.searchData.endDate[1];
      }
      if (this.searchData.startDate) {
        params.startDateBegin = this.searchData.startDate[0];
        params.startDateEnd = this.searchData.startDate[1];
      }
      if (this.searchData.contractSalesman) {
        params.contractSalesman = this.searchData.contractSalesman;
      }
      if (this.searchData.settleType) {
        params.settleType = this.searchData.settleType;
      }

      if (this.searchData.agencyId) {
        params.agencyId = this.searchData.agencyId;
      }
      if (this.searchData.agencyBillNo) {
        params.agencyBillNo = this.searchData.agencyBillNo;
      }
      if (this.searchData.parentCompId) {
        params.parentCompId = this.searchData.parentCompId;
      }
      if (this.searchData.customerNature) {
        params.customerNature = this.searchData.customerNature;
      }
      this.$post("/biz/new/bill/project/export", params)
        .then((res) => {
          if (res.status == "0") {
            if (!this.downloadItems.includes(res.data.taskId)) {
              this.$store.dispatch("pushDownloadItems", res.data.taskId);
            } else {
              this.$message.warning(
                "[" + res.data.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(re.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    //批量上传
    NotesbatchUpload() {
      this.centerDialogVisible = true;
    },
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/结算列表备注批量上传模板(新).xlsx";
      link.setAttribute("download", "结算列表备注批量上传模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // 导入备注
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExportIn() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$confirm("是否导入此文件?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.fileKey) {
              const parmar = {
                key: this.fileKey,
              };
              this.$post(
                "/biz/new/bill/project/importExcel",
                parmar,
                5000
              )
                .then((res) => {
                  if (res.status == 0) {
                    this.projectCount = res.data.projectCount;
                    this.errorCount = res.data.errorCount;
                    this.successCount = res.data.successCount;
                    this.taskIds = res.data.taskIds;
                    if (res.data.projectCount - res.data.successCount == 0) {
                      this.$message({
                        type: "success",
                        message: "导入成功",
                      });
                    }

                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.taskIds == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post("/sys/download/queryDownloadState",
          { taskIds: this.taskIds },
        ).then((res) => {
          if (res.status == "0") {
            let list = [];
            list.push(res.data);
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item[0].taskId);
              } else {
                console.log(item.fileName)
                this.$message.warning(
                  "[" + item[0].fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(re.message);
          }
        });
      }
    },
    doCloseload() {
      this.centerDialogVisible = false;
      this.fileKey = "";
      this.fileName = "";
      this.errorCount = "0";
      this.projectCount = "0";
      this.successCount = "0";
      this.getData();
    },
    // 导出缓存数据
    exportCachedData(row) {
      const params = {
        billFinishType: this.searchData.billFinishType
      };
      if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.billNo) {
        params.billNo = this.searchData.billNo;
      }
      if (this.searchData.payBillNo) {
        params.payBillNo = this.searchData.payBillNo;
      }
      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.billState) {
        params.billState = this.searchData.billState;
      }
      if (this.searchData.arrival) {
        params.arrival = this.searchData.arrival;
      }
      if (this.searchData.projectState) {
        params.projectState = this.searchData.projectState;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      if (this.searchData.signCompId) {
        params.compId = this.searchData.signCompId;
      }
      if (this.searchData.courseProvideId) {
        params.courseProvideId = this.searchData.courseProvideId;
      }
      if (this.searchData.endDate) {
        params.endDateBegin = this.searchData.endDate[0];
        params.endDateEnd = this.searchData.endDate[1];
      }
      if (this.searchData.startDate) {
        params.startDateBegin = this.searchData.startDate[0];
        params.startDateEnd = this.searchData.startDate[1];
      }
      if (this.searchData.contractSalesman) {
        params.contractSalesman = this.searchData.contractSalesman;
      }
      if (this.searchData.settleType) {
        params.settleType = this.searchData.settleType;
      }

      if (this.searchData.agencyId) {
        params.agencyId = this.searchData.agencyId;
      }
      if (this.searchData.agencyBillNo) {
        params.agencyBillNo = this.searchData.agencyBillNo;
      }
      if (this.searchData.parentCompId) {
        params.parentCompId = this.searchData.parentCompId;
      }
      if (this.searchData.customerNature) {
        params.customerNature = this.searchData.customerNature;
      }
      this.$post("/biz/new/bill/project/cache/export", params)
        .then((res) => {
          if (res.status == "0") {
            const arr = [...res.data];
            for (let item of arr) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 11) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 45;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.query.refresh == true) {
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.itemLabel {
  min-width: 5rem;
}

.upload-btns {
  height: 40px;

  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}

.el-textarea__inner {
  min-height: 7.5rem !important;
  resize: none;
}

.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }

  .export-box {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;

      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    >div {
      padding-left: 30px;
    }

    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}

.dialog-footer {
  margin-top: .75rem;
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="less">
.exportdialog {
  .upload-workers {
    height: 30px;

    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;

      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>
